import React, { useState, useCallback, useEffect } from "react";

import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import "./ConnectLogin.css";
import { Post } from "../../services/apiServices";
import { networkUrls } from "../../services/networkUrls";
import ReuseableSnackbar from "../../components/common/Snackbar/ResueableSnackbar";
import {connectEmployeeDetails,connectEmployeeDetailsSales, employeeDetailsFinance, employeeDetailsSales} from "../../utils/helpers/constants";


const ConnectLogin = () => {
  const locationHash = window.location.hash; // Get the location hash from the window object
  const navigate = useNavigate();
  const [unAuthorizedUser, setUnAuthorizedUser] = useState(false);
  const [snackbarData, setSnackbarData] = useState({ color: "", message: "" });
  const [isShowSnackbar, setIsShowSnackbar] = React.useState(false);
  const hashParams = new URLSearchParams(
    locationHash ? locationHash.substr(1) : ""
  );
  const accessToken: any = hashParams.get("access_token");
  const refreshToken = hashParams.get("refresh_token");
  const instanceUrl = hashParams.get("instance_url");
  const idParam = hashParams.get("id");
  const id: any = idParam?.substring(idParam.lastIndexOf("/") + 1);
  Cookies.set("employee_id", id);
  Cookies.set("salesforce_token", accessToken);
  const role = Cookies.get("token_role");

  console.log(role,"role in connectlogin")
  const AuthenticateConnectLoginUser = useCallback(async () => {
    if (accessToken && instanceUrl) {
      setUnAuthorizedUser(false);
      try {
        const params = {
          q: `SELECT FIELDS(ALL) FROM User WHERE id='${id}'`,
        };
        const response = await axios.get(
         role==="Sales" ?  employeeDetailsSales : employeeDetailsFinance,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        console.log(response,"response");
      
        if (response.data.API_Status === 200) {
          console.log("if condition",response)
          const fullName: any = `${response.data.data?.first_name} ${response.data.data?.last_name}`;
          if (
            response.data.data?.user_department === "Sales" ||
            response.data.data?.user_department === "Finance"
          ) {
            SettingCookies(
              response.data.data.user_email,
              response.data.data.employeeR_id,
              fullName,
              response.data.data.user_department,
              response.data.data.user_superaccess
            );
          } else {
            setIsShowSnackbar(true);
            setSnackbarData({ color: "error", message: "Login Failed" });
            console.log("else condition",response)
            setUnAuthorizedUser(true);
          }
        }
        //API CALL TO BACKEND:
      } catch (error) {
        setUnAuthorizedUser(true);
        console.log(error, "errorerror");
      }
    } else {
      setUnAuthorizedUser(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, refreshToken, instanceUrl]);
  useEffect(() => {
    // let user_role = Cookies.get("user_role");
    // let jwtToken = Cookies.get("jwtToken");
    // if (jwtToken === undefined && user_role === undefined) {
    AuthenticateConnectLoginUser();
    // } else {
    //   navigate("/login");
    // }
  }, [accessToken, refreshToken, instanceUrl]);

  const SettingCookies = async (
    userEmail: string,
    userId: string,
    userName: string,
    userRole: string,
    userSuperAccess: boolean
  ) => {
    const payload: any = {
      email: userEmail,
      sf_id: userId,
      role: userRole,
      username: userName,
    };
    try {
      const response = await Post(networkUrls.connectLogin, payload, false);
      if (response?.data?.api_status === 200) {
        Cookies.set("accessToken", response.data.data.jwttoken);
        Cookies.set("user_role", userRole);
        Cookies.set("super_admin", JSON.stringify(userSuperAccess));
        if (userRole === "Sales" || userRole === "Finance") {
          setIsShowSnackbar(true);
          setSnackbarData({ color: "success", message: "Login Successful" });
          navigate(userRole === "Sales" ? "/" : "/admin-dashboard");
        } else {
          setUnAuthorizedUser(true);
          setIsShowSnackbar(true);
          setSnackbarData({ color: "error", message: "Login Failed" });
        }
      }
    } catch (error) {
      console.log("Error while login", error);
      setIsShowSnackbar(true);
      setUnAuthorizedUser(true);
    }
  };

  return (
    <div className="login-bg connect-wrapper">
      <Box className="flex justify-center items-center w-full">
        {!unAuthorizedUser && (
          <>
            <>
              <span className="text-[1.25rem] mr-[2rem] text-[#fff]">
                <span className="AuthenticationLoader">
                  Authenticating & Authorizing User. Please wait !
                </span>
              </span>
            </>
          </>
        )}
        {unAuthorizedUser && (
          <>
            <span className="AuthenticationLoader">
              Authentication Failed !
            </span>
            <Button onClick={() => navigate("/")}>Go to Login</Button>
          </>
        )}
      </Box>
      {isShowSnackbar && (
        <ReuseableSnackbar
          isShowSnackbar={isShowSnackbar}
          setIsShowSnackbar={setIsShowSnackbar}
          colour={snackbarData.color}
          message={snackbarData.message}
        />
      )}
    </div>
  );
};

export default ConnectLogin;
