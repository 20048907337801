import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import myImage from "../../assests/images/Group 2066.png";
import { makeStyles } from "@mui/styles";
import { networkUrls } from "../../services/networkUrls";
import { Get } from "../../services/apiServices";
import View from "./ProjectView/View";
import ReuseableSnackbar from "../../components/common/Snackbar/ResueableSnackbar";
import { CircularProgress } from "@mui/joy";
import Create from "./Create";
import "./User.css";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import ReuseableModal from "../../components/common/Modal/Modal";
import Cookies from "js-cookie";
const useStyles = makeStyles({
  root: {
    "& .MuiCheckbox-root .MuiSvgIcon-root": {
      color: "#1670C3",
      borderRadius: "1px",
    },
    "& .Mui-checked .MuiSvgIcon-root": {},
  },
});

interface DropdownOption {
  id?: number;
  name: string;
  region?: string;
}

interface CheckedItems {
  [key: string]: boolean;
}

interface AdditionalFields {
  level: string;
  no_of_resources: number;
  total_budget_hours: number;
}

interface StreamFields {
  [key: string]: AdditionalFields[];
}

const User = () => {
  const classes = useStyles();
  const [allProjects, setAllProjects] = useState<any[]>([]);
  const [loader, setLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [streams, setStreams] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState<number>(0);
  const [dropdowns, setDropdowns] = useState<Record<string, DropdownOption[]>>(
    {}
  );
  const [open, setOpen] = React.useState(false);
  const [isShowSnackbar, setIsShowSnackbar] = useState(false);
  const [superAdmin, setSuperAdmin] = useState("");
  const [userRole, setUserRole] = useState("");
  const [isNavigate, setIsNavigate] = useState(false);
  const [createpage, setCreatePage] = useState(true);
  const [value, setValue] = React.useState("1");
  const [checkedItems, setCheckedItems] = useState<CheckedItems>({});
  const [snackbarData, setSnackbarData] = useState({ color: "", message: "" });
  const [streamFields, setStreamFields] = useState<StreamFields>({});

  const [navigationId, setNavigationId] = useState<number>(0);
  const [isView, setIsView] = useState(false);
  const [formErrors, setFormErrors] = useState<{
    opportunityName: string;
    region: string;
    opportunityType: string;
    check: string;
    currency: string;
    [key: string]: string; // Index signature for dynamic keys
  }>({
    opportunityName: "",
    region: "",
    opportunityType: "",
    check: "",
    currency: "",
  });

  const [formData, setFormData] = useState({
    opportunityName: "",
    region: "none",
    opportunityType: "none",
  });

  const handleCreatePage = () => {
    setCreatePage(true);
    setIsView(false);
    setIsNavigate(false);
    setSelectedItemId(0);
    setFormErrors({
      opportunityName: "",
      region: "",
      opportunityType: "",
      check: "",
      currency: "",
    })
    setCheckedItems({})
    setStreamFields({})
    setFormData({
      opportunityName: "",
      region: "none",
      opportunityType: "none",
    })
  };


  const getAllProjects = async () => {
    try {
      const response = await Get(networkUrls.getAllProjects,true);
      if (response?.data?.api_status === 200) {
        setLoader(true);
        const data = response?.data?.data?.rows.map(
          (project: any, index: any) => ({
            ...project,
            id: index + 1,
            stream: project.projectItems[0]?.projectStreams.name,
            level: project.projectItems[0]?.projectLevels.name,
            projectId : project.id
          })
        );
        setAllProjects(data);
      } else {
        setLoader(false);
        setAllProjects([]);
      }
    } catch (error) {
      console.log("Error getting Projects", error);
    }
    setIsLoading(false);
  };


  const isSwitch = (id: number) => {
    setIsView(true);
    setNavigationId(id);
    setSelectedItemId(id);
    setCreatePage(false);
  };

  const getAllDropDowns = async () => {
    try {
      const response = await Get(networkUrls.getDropdownValues, true);
      if (response?.data?.api_status === 200) {
        setDropdowns(response?.data?.data);
        setStreams(response?.data?.data?.stream);
      }
    } catch (error) {
      console.log("Error getting Dropdown values", error);
    }
  };

  useEffect(() => {
    getAllProjects();
    getAllDropDowns();
  }, []);

  useEffect(() => {
    if (isShowSnackbar) {
      setValue("2");
    }
  }, [isShowSnackbar]);
  const navigate = useNavigate()

  useEffect(() => {
    let superAdmin: any = Cookies.get("super_admin");
    let role: any = Cookies.get("user_role");
    setUserRole(role);
    setSuperAdmin(superAdmin);
  }, []);


  const handleChange = (e: any) => {
    if (e.target.value === "Finance") {
      Cookies.set("user_role", "Sales");
      setUserRole("Sales");
      navigate("/")
    } else {
      Cookies.set("user_role", "Finance");
      setUserRole("Finance");
      navigate("/admin-dashboard")
    }
  };

  const handleLogout = () => {
    setOpen(true);
  };

  return (
    <Grid
      sx={{
        minHeight: !isView ? "100vh" : "150vh",
        backgroundColor: "#EBEFF5",
      }}
    >
      <Grid
        sx={{
          height: "32vh",
          // backgroundColor: "grey",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
        className="backgroundMainImage"
      >
        <span
          style={{
            color: "white",
            fontWeight: 700,
            fontSize: "25px",
            marginBottom: "40px",
          }}
        >
          Project estimation calculator
        </span>

        {superAdmin === "true" ? (
          <MenuItem
            style={{
              width: "192px",
              height: "38px",
              padding: "7px 48px 7px 11px",
              borderRadius: " 4px",
              position: "absolute",
              right: "140px",
              top: "42%",
              transform: "translateY(-50%)",
            }}
          >
            <Typography className="badge" style={{ color: "white" }}>
              Sales
            </Typography>
            <Switch
              checked={userRole === "Finance"}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
              size="small"
              value={userRole}
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked": {
                  color: "white", 
                },
                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                  backgroundColor: "white", 
                },
                "& .MuiSwitch-switchBase": {
                  color: "white", 
                },
                "& .MuiSwitch-track": {
                  backgroundColor: "white",
                },
              }}
            />
            <Typography className="badge" style={{ color: "white" }}>
              Finance
            </Typography>
          </MenuItem>
        ) : (
          <></>
        )}
        <Button
          onClick={handleLogout}
          sx={{
            position: "absolute",
            color: "white",
            right: "20px",
            top: "42%",
            transform: "translateY(-50%)",
          }}
        >
          <LogoutIcon />
        </Button>
      </Grid>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          // bottom: "0",
          // left: "50%",
          transform: "translateY(-60px)",
          // height: "78%",
          // margin: "0 20px",
        }}
      >
        <Grid container spacing={0} style={{ paddingBottom: "20px" }}>
          <Grid item xs={0.15}></Grid>
          <Grid
            item
            xs={2.85}
            sx={{
              // width: "35%",
              backgroundColor: "#FCFDFD",
              borderRadius: "10px",
              // marginRight:"14px"
              // overflow:"auto"
              height: "75vh",
            }}
          >
            <Grid container spacing={0}>
              <Grid
                item
                xs={8}
                style={{ display: "flex", alignItems: "center" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "10%",
                  }}
                >
                  <img
                    src={myImage}
                    alt="calculator_logo"
                    height="25px"
                    width="25px"
                    style={{ marginRight: "8px" }}
                  />
                  <span
                    style={{
                      color: "#1B305D",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Opportunities
                  </span>
                </div>
              </Grid>
              <Grid item xs={4}>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    color: "#1B305D",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                  onClick={handleCreatePage}
                >
                  New&nbsp;
                  <AddCircleIcon color="primary" />
                </p>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  // maxHeight: 400,
                  height: "400px",
                  overflow: "auto",
                  "&::-webkit-scrollbar": {
                    width: "2px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "white",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "grey",
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#555",
                  },
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : allProjects.length > 0 ? (
                  <List sx={{ backgroundColor: "white" }}>
                    {allProjects.map((item, index) => (
                      <React.Fragment key={index}>
                        <ListItem
                          sx={{
                            backgroundColor:
                              selectedItemId ===
                              item.projectId
                                ? "#1B305D"
                                : "transparent",
                            "&:hover": {
                              backgroundColor:
                                selectedItemId ===
                                item.projectId
                                  ? "#1B305D"
                                  : "#1B305D",
                              "& .MuiListItemText-primary": {
                                color:
                                  selectedItemId ===
                                  item.projectId
                                    ? "#fff"
                                    : "#fff",
                              },
                            },
                          }}
                        >
                          <ListItemButton
                            onClick={() =>
                              isSwitch(item.projectId)
                            }
                          >
                            <ListItemText
                              primary={item.project_name}
                              sx={{
                                textTransform: "capitalize",
                                color:
                                  selectedItemId ===
                                  item.projectId
                                    ? "#fff"
                                    : "#1670C3",
                              }}
                            />
                          </ListItemButton>
                        </ListItem>

                        {index < allProjects.length - 1 && <Divider />}
                      </React.Fragment>
                    ))}
                  </List>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No project found
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={0.15}></Grid>
          <Grid
            item
            xs={8.5}
            sx={{
              minHeight: "100%",
              width: "100%",
              borderRadius: "10px",
              backgroundColor: "#FCFDFD",
              padding: "0px 20px 10px 20px",
              // border: "1px solid #1B305D",
              // marginBottom: "40px",
            }}
          >
            {isView || isNavigate ? (
              <>
                <Grid className="view">
                  {/* {navigation} */}
                  <View
                    projectId={navigationId}
                    setValue={setValue}
                    value={value}
                    getAllProjects={getAllProjects}
                  />
                </Grid>
              </>
            ) : (
              <>
                {createpage && (
                  <Create
                    getAllProjects={getAllProjects}
                    setIsShowSnackbar={setIsShowSnackbar}
                    setIsNavigate={setIsNavigate}
                    setSnackbarData={setSnackbarData}
                    setNavigationId={setNavigationId}
                    setSelectedItemId={setSelectedItemId}
                    setFormErrors={setFormErrors}
                    formErrors={formErrors}
                    checkedItems={checkedItems}
                    setCheckedItems={setCheckedItems}
                    streamFields={streamFields}
                    setStreamFields={setStreamFields}
                    formData={formData}
                    setFormData={setFormData}
                  />
                )}
              </>
            )}
          </Grid>
          <Grid item xs={0.1}></Grid>
        </Grid>
      </div>
      {isShowSnackbar && (
        <ReuseableSnackbar
          isShowSnackbar={isShowSnackbar}
          setIsShowSnackbar={setIsShowSnackbar}
          colour={snackbarData.color}
          message={snackbarData.message}
        />
      )}

      {open && <ReuseableModal open={open} setOpen={setOpen} type="logout" />}
    </Grid>
  );
};

export default User;
