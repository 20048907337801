import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReuseableTextfield from "../components/common/ReuseableTextfield/ReuseableTextfield";
import LoginImg from "../assests/images/login.avif";
import ReusableButton from "../components/common/ReuseableButton/ReusableButton";
import { Post } from "../services/apiServices";
import { networkUrls } from "../services/networkUrls";
import { response } from "express";
import Cookies from "js-cookie";
import {connectDetails, encryptMessageWithExpiry, verifyToken,connectEmployeeDetailsSales, connectDetailsSales, connectLoginUrlForSales, connectLoginUrlForFinance} from "../utils/helpers/constants";
import ReuseableSnackbar from "../components/common/Snackbar/ResueableSnackbar";
import logo from "../assests/images/logo.png"
import "./Login.css"
const Login = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [submitBtn, setSubmitBtn] = useState(false);
  const [message, setMessage] = useState("");
 
  const [isShowSnackbar, setIsShowSnackbar] = React.useState(false);
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  

  const handleConnectLogin = (): void => {
    const role = Cookies.get("token_role");
    // Ensure CONNECT_LOGIN_URL is defined in your environment variables
    const loginUrl: string | undefined = role==="Sales" ? connectLoginUrlForSales : connectLoginUrlForFinance

    console.log(loginUrl,"loginurl");
      
    if (loginUrl) {
      window.location.href = loginUrl;
    } else {
      console.error("CONNECT_LOGIN_URL is not defined.");
    }
  };

  const handleOtpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtp(event.target.value);
  };

  const handleSubmitOtp = async (e: any) => {
    e.preventDefault();

    setSubmitBtn(true);
    try {
      const reponse = await Post(networkUrls.sendOtp, { email }, false);
      // if (response?.data?.api_status === 200){
      //     Cookies.set("AccessToken", response.data.data.accessToken)
      //     setIsShowSnackbar(true)
      //     setMessage("OTP Sent Successfully")
      // }
    } catch (error) {
      console.log("Error while login", error);
    }
  };

  const handleVerifyOtp = async (e: any) => {
    e.preventDefault();

    try {
      const reponse = await Post(networkUrls.verifyOtp, { email }, false);
      // if (response?.data?.api_status === 200){
      //     setMessage("OTP Verified Successfully")
      // }
    } catch (error) {
      console.log("Error while login", error);
    }
  };

  function getTokenFromParams() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('t');
  }
  
  // Extract the token

  useEffect(()=>{
    console.log("in use effect");
    const token = getTokenFromParams();
    
  verifyToken(token)
   
    

  },[])

  useEffect(()=>{
    Cookies.remove("user_role");
    Cookies.remove("employee_id");
    Cookies.remove("salesforce_token");
    Cookies.remove("accessToken");
    Cookies.remove("super_admin");
    
  },[]);
  return (
    <>
      <Grid
        className="login-container"
      >
        <Grid sx={{display:"flex",justifyContent:"center",alignItems:"enter",flexDirection:"column"}}>
        <h1 style={{color:"white"}}>Project estimation calculator</h1>
        <Grid sx={{display:"flex",justifyContent:"center"}}>
        <Button sx={{backgroundColor:"white",color:"black",width:"100px",fontWeight:"bold",textTransform:"capitalize", "&:hover": {
      backgroundColor: "white", 
    },}} onClick={handleConnectLogin}>Login</Button>
        </Grid>
        </Grid>
        <Grid sx={{bottom:0,position:"absolute",marginBottom:"15px"}}>
            <img src={logo} alt="absyz-logo" className="logo"/>
        </Grid>
       
      </Grid>
      {isShowSnackbar && (
        <ReuseableSnackbar
          isShowSnackbar={isShowSnackbar}
          setIsShowSnackbar={setIsShowSnackbar}
          colour="success"
          message={message}
        />
      )}
    </>
  );
};

export default Login;
